import React from 'react'

export default function Footer() {
    return (
        <footer className="bg-primary">
            <div className="container">
                footer
            </div>
        </footer>
    )
}
